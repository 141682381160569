import React, { Reducer, useEffect, useReducer } from 'react'

export default function Test() {

    
  useEffect(() => {
    // console.log('monitors', monitors)
  }, [])
  
  return (
      <>
        <button>
          Add monitor
        </button>
      </>
  )
}
