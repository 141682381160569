import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MonitorsService from "../../services/monitors/MonitorsService";
import RapportMonitorService from "../../services/rapport-monitor/RapportMonitorService";
import RowDashboard from "./RowDashboard";

interface IDashboardAlerte {
  Rapports: any;
  NameMonitor?: any;
  setNameMonitor?: any;
  search?: any;
  research?: any;
  IndexPagination?: any;
  Entries?: any;
  editIndexPagination?: any;
}

export default function Dashboard({
  Rapports,
  NameMonitor,
  search,
  research,
  IndexPagination,
  Entries,
  editIndexPagination,
}: IDashboardAlerte) {
  console.log("Rapports", Rapports);
  return (
    <>
      <div className="container-row">
        <div className="container-col-100">
          <div id="dashboard-alerte" className="dashboard">
            <div className="dashboard-header">
              <div className="presentation">
                <h3 className="big-title">Journal d'Alerte</h3>
                <div className="subtitle">
                  {Rapports && Rapports.length} alertes trouvées
                  {NameMonitor ? ` pour le Moniteur : ${NameMonitor}` : ""}
                </div>
              </div>
              {/* BARRE DE RECHERCHE */}
              <div className="navbar-tool-searchbar">
                <div className="navbar-tool-searchbar-icon">
                  <span className="material-icons">search</span>
                </div>
                <div className="navbar-tool-searchbar-input">
                  <input
                    placeholder="Parcours..."
                    type="text"
                    value={search}
                    onChange={(e) => research(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="cell title">Moniteur</div>
                <div className="cell title">Langue</div>
                <div className="cell title">Détails</div>
                <div className="cell title">Nom Etape</div>
                <div className="cell title">Type Erreur</div>
                <div className="cell title">Date</div>
              </div>
            </div>
            {/* <div className="dashboard-filter">

                        </div> */}
            <div className="dashboard-body">
              {Rapports.length > 0 && (
                <>
                  {/* {Rapports.slice(0,Entries).map((Rapport : any, i : number) => { */}
                  {Rapports.data
                    .slice(
                      Entries * (IndexPagination - 1),
                      Entries * IndexPagination
                    )
                    .map((Rapport: any, i: number) => {
                      return (
                        <RowDashboard
                          key={Rapport.id}
                          id={Rapport.id}
                          status={Rapport.status && Rapport.status}
                          errorName={Rapport.name && Rapport.name}
                          errorType={Rapport.type}
                          errorMsg={Rapport.errorMsg}
                          createdAt={Rapport.createdAt && Rapport.createdAt}
                          monitor={Rapport.monitor && Rapport.monitor}
                        />
                      );
                    })}
                </>
              )}
            </div>
            <div className="dashboard-footer">
              <div className="text-entries">
                {Entries} Entrées par pages
                {/* Showing 1 to {Rapports && Rapports.length} */}
              </div>
              {Rapports.length > Entries && (
                <div className="pagination">
                  <div
                    onClick={() => editIndexPagination("previous")}
                    className="pagination-prev"
                  >
                    <span className="material-icons">navigate_before</span>
                  </div>
                  <div className="pagination-index">
                    <span>
                      {IndexPagination} / {Math.ceil(Rapports.length / Entries)}
                    </span>
                  </div>
                  <div
                    onClick={() => editIndexPagination("next")}
                    className="pagination-next"
                  >
                    <span className="material-icons">navigate_next</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
