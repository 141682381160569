import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <>
            <div className="container-main">
                <div className="container-row">
                    <div className="container-col-100">
                        <h1>Not Found</h1>    
                        <Link to={'/moniteur'}>
                            <button>
                                Go to the Home page
                            </button>
                        </Link>    
                    </div>
                </div>    
            </div>
        </>
    )
}
