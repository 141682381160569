import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
const moment = require('moment');

interface IRowDashboard {
    id?: number | any,
    monitor?: any,
    errorName?: string,
    errorType?: string,
    errorMsg?: string,
    status?: number,
    createdAt?: any,
}

export default function RowDashboard({id, errorName, errorType, errorMsg,  status, createdAt, monitor} : IRowDashboard) {
    return (
        <div className='row'>
            <div className='cell'><a href={`${process.env.REACT_APP_BASE_URL}admin/content-manager/collectionType/api::monitor.monitor/${monitor && monitor.id}`} target={"_blank"}>{monitor && monitor.general.name}</a></div>
            {/* <div className='cell'><Link to={`/monitor/${id}`}>{monitor && monitor.general.name}</Link></div> */}
            <div className='cell'>{monitor && monitor.general.langue}</div>
            <div className='cell'>
                <Link to={`/alerte/${id}`}>
                    <span className="material-icons">menu_book</span>
                </Link>
            </div>
            <div className='cell'>{errorName && errorName}</div>
            <div className='cell'>{errorType && errorType}</div>
            <div className='cell'>{createdAt && moment(createdAt).format('L')} à {createdAt && moment(createdAt).format('LTS')}</div>
        </div>
    )
}
