import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import MonitorsService from '../../services/monitors/MonitorsService';
import RapportMonitorService from '../../services/rapport-monitor/RapportMonitorService';
const moment = require('moment');



export default function DetailMonitor() {
    const [Rapport, setRapport] = useState<any>();
    const [Monitor, setMonitor] = useState<any>();
    const [error, setError] = useState<any>();
    const params : any = useParams();
    const fetchData =  async () => {
        const reponseRapport = await RapportMonitorService.getOne(params.alerteId);
        setMonitor(reponseRapport.data.monitor)
        console.log('reponseRapport', reponseRapport)
        if (reponseRapport.status === 200) await setRapport(reponseRapport.data);
        if (reponseRapport.status !== 200 || !reponseRapport.status) await setError(reponseRapport.data.error);
    }
    console.log('Rapport.urlScreenshot :>> ', Rapport?.urlScreenshot);
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <section className='container-main detail-monitor'>
                <div className="container-row">
                    <div className="container-col-50">
                        <div className="card-monitor">
                            {Monitor &&
                                <>
                                    <div className="card-header">
                                        <div>Nom :<br/> {Monitor.general && Monitor.general.name}</div>
                                        <div>Intervalle de vérification :<br/> {Monitor && Monitor.general.interval_verif} minutes</div>
                                    </div>
                                    <div className="card-body">
                                        {/* <div>Url :<br/> {Monitor && Monitor.url}</div> */}
                                        <div>Mode :<br/> {Monitor.status && Monitor.status.mode}</div>
                                    </div>
                                    <div className="card-footer">
                                        <div>Status : {Monitor.status && Monitor.status.isActive ? "On" : "Off"}</div>
                                        <div>Alerte : {Monitor.status && Monitor.status.sendAlert ? "On" : "Off"}</div>    
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="container-col-50">
                        <div className="card-rapport">
                            {Rapport &&
                                <>
                                    <div className="card-header">
                                    <div>Nom Erreur :<br/> {Rapport.name && Rapport.name}</div><div>Type d'erreur :<br/> {Rapport.type && Rapport.type}</div>
                                    </div>
                                    <div className="card-body">
                                        <div>Le : {Rapport ? `${moment(Rapport.createdAt).format('L')} à ${moment(Rapport.createdAt).format('LTS')}` : ""}</div>
                                        <div>Message d'erreur :<br/> {Rapport.errorMsg && Rapport.errorMsg}</div>
                                    </div>
                                    <div className="card-footer">
                                        Temps d'éxécution : {Rapport?.durationParcours} secondes
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {Rapport?.urlScreenshot &&
                    <div className="container-row">
                        <div className="container-col-100">
                            <img src={Rapport?.urlScreenshot} alt="Image erreur" style={{ width: '100%'}}/>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}
