import { useEffect, useState } from "react";
import "chart.js/auto";
import { Doughnut, Line } from "react-chartjs-2";
import RapportMonitorService from "../../services/rapport-monitor/RapportMonitorService";

interface IStatistiquesAlerte {
  ReportsFilter: any;
  LastReportName?: any;
  filterRaports?: any;
  DateRapport: any;
  setDateRapport: any;
  ModeRapport: any;
  setModeRapport: any;
}

export default function Statistiques({
  ReportsFilter,
  DateRapport,
  setDateRapport,
  ModeRapport,
  setModeRapport,
}: IStatistiquesAlerte) {
  const [ReportsFilterWithDayFR, setReportsFilterWithDayFR] = useState<any>();
  const [ReportsFilterWithDayBE, setReportsFilterWithDayBE] = useState<any>();
  const [ReportsFilterWithDayNL, setReportsFilterWithDayNL] = useState<any>();
  // Graphique
  const data = {
    labels: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ],
    datasets: [
      {
        label: "FR",
        data: [
          ReportsFilterWithDayFR?.lundi.length,
          ReportsFilterWithDayFR?.mardi.length,
          ReportsFilterWithDayFR?.mercredi.length,
          ReportsFilterWithDayFR?.jeudi.length,
          ReportsFilterWithDayFR?.vendredi.length,
          ReportsFilterWithDayFR?.samedi.length,
          ReportsFilterWithDayFR?.dimanche.length,
        ],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "BE",
        data: [
          ReportsFilterWithDayBE?.lundi.length,
          ReportsFilterWithDayBE?.mardi.length,
          ReportsFilterWithDayBE?.mercredi.length,
          ReportsFilterWithDayBE?.jeudi.length,
          ReportsFilterWithDayBE?.vendredi.length,
          ReportsFilterWithDayBE?.samedi.length,
          ReportsFilterWithDayBE?.dimanche.length,
        ],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "NL",
        data: [
          ReportsFilterWithDayNL?.lundi.length,
          ReportsFilterWithDayNL?.mardi.length,
          ReportsFilterWithDayNL?.mercredi.length,
          ReportsFilterWithDayNL?.jeudi.length,
          ReportsFilterWithDayNL?.vendredi.length,
          ReportsFilterWithDayNL?.samedi.length,
          ReportsFilterWithDayNL?.dimanche.length,
        ],
        borderColor: "rgb(23, 173, 55)",
        backgroundColor: "rgba(23, 173, 55, 0.5)",
      },
    ],
  };
  const options = { responsive: true };

  const setRapportsLangues = async () => {
    setReportsFilterWithDayFR(
      await RapportMonitorService.filterRapportsWithDay(
        ReportsFilter.filter(
          (rapport: any) => rapport?.monitor?.general?.langue == "FR"
        )
      )
    );
    setReportsFilterWithDayBE(
      await RapportMonitorService.filterRapportsWithDay(
        ReportsFilter.filter(
          (rapport: any) => rapport?.monitor?.general?.langue == "BE"
        )
      )
    );
    setReportsFilterWithDayNL(
      await RapportMonitorService.filterRapportsWithDay(
        ReportsFilter.filter(
          (rapport: any) => rapport?.monitor?.general?.langue == "NL"
        )
      )
    );
  };

  useEffect(() => {
    if (ReportsFilter?.length > 0) setRapportsLangues();
  }, [DateRapport]);

  return (
    <>
      <div className="container-row">
        <div className="container-col-50">
          <div className="bloc-info">
            <h2>Reports</h2>
            <div id="chart-alerte" className="container-graphique">
              <Doughnut
                data={{
                  labels: ["FR", "BE", "NL"],
                  datasets: [
                    {
                      backgroundColor: ["red", "blue", "green"],
                      data: [
                        ReportsFilter
                          ? ReportsFilter.filter(
                              (rapport: any) =>
                                rapport?.monitor?.general?.langue == "FR"
                            ).length
                          : [],
                        ReportsFilter
                          ? ReportsFilter.filter(
                              (rapport: any) =>
                                rapport?.monitor?.general?.langue == "BE"
                            ).length
                          : [],
                        ReportsFilter
                          ? ReportsFilter.filter(
                              (rapport: any) =>
                                rapport?.monitor?.general?.langue == "NL"
                            ).length
                          : [],
                      ],
                    },
                  ],
                }}
                updateMode={"active"}
                height={"200"}
              />
            </div>
            <br />
            <p>
              {ReportsFilter?.length} alertes ont été trouver
              {DateRapport == "ThisWeek" && " cette semaine"}
              {DateRapport == "LastWeek" && " la semaine dernière"}
              {DateRapport == "ThisMonth" && " ce mois-ci"}
            </p>
          </div>
        </div>
        <div className="container-col-50">
          <div className="bloc-info">
            <h2>Récapitulatif</h2>
            <br />
            Nombre d'alertes sur Carrefour Voyages
            {DateRapport == "ThisWeek" && " cette semaine"}
            {DateRapport == "LastWeek" && " la semaine dernière"}
            {DateRapport == "ThisMonth" && " ce mois-ci"} :
            <br />
            <br />
            Carrefour Voyages FR :{" "}
            {ReportsFilter &&
              ReportsFilter.filter(
                (rapport: any) => rapport?.monitor?.general?.langue == "FR"
              ).length}
            <br />
            Carrefour Voyages BE FR :{" "}
            {ReportsFilter &&
              ReportsFilter.filter(
                (rapport: any) => rapport?.monitor?.general?.langue == "BE"
              ).length}
            <br />
            Carrefour Voyages BE NL :{" "}
            {ReportsFilter &&
              ReportsFilter.filter(
                (rapport: any) => rapport?.monitor?.general?.langue == "NL"
              ).length}
            <br />
            <br />
            Alerte non répertorier :{" "}
            {ReportsFilter &&
              ReportsFilter.filter((rapport: any) => !rapport.monitor).length}
            <br />
            <br />
            <a href={"#chart-alerte"}>Graphique alerte</a>
            <a href={"#dashboard-alerte"}>Dashboard alerte</a>
          </div>
        </div>
      </div>
      <div className="container-row">
        <div className="container-col-100">
          <div className="container-tableau">
            <div className="container-filter">
              <div className="container-select-filter">
                <button onClick={() => setDateRapport("LastWeek")}>
                  La semaine dernière
                </button>
                <button onClick={() => setDateRapport("ThisWeek")}>
                  Cette semaine
                </button>
                <button onClick={() => setDateRapport("ThisMonth")}>
                  Ce mois
                </button>
                {/* <a>Lien vers canva</a> */}
              </div>
              <div className="container-select-filter">
                <button onClick={() => setModeRapport("production")}>
                  Production
                </button>
                <button onClick={() => setModeRapport("all")}>Tous</button>
                {/* <a>Lien vers canva</a> */}
              </div>
            </div>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}
