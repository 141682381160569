import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import Signin from './pages/Signin';
import './scss/main.scss'

import Monitors from './pages/Monitors';
import Alertes from './pages/Alertes';
import Test from './pages/Test';
import DetailAlerte from './components/Alertes/DetailAlerte';
import NotFound from './components/NotFound';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
      <Routes>
        <Route path="/connexion" element={<Signin />} />
        <Route path="/" element={<App />} >
          <Route path="moniteur" element={<Monitors />} />
          <Route path="moniteur/alertes/:monitorId" element={<Alertes />} />
          <Route path="alerte" element={<Alertes />} />
          <Route path="alerte/:alerteId" element={<DetailAlerte />} />
          <Route path="test" element={<Test />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
  </BrowserRouter>,
  rootElement
);
