import axios from "axios";
import authHeader from "../services/Auth/AuthHeader";

export default axios.create({
  baseURL: `${
    process.env.NODE_ENV === "production"
      ? "https://back-santa-monica.cv-tools.fr/"
      : process.env.REACT_APP_BASE_URL
  }api/`,
  headers: authHeader(),
});
