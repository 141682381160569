import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMonitor } from "../../services/monitors/IMonitors";
import MonitorsService from "../../services/monitors/MonitorsService";

interface IRowDashboard {
  data: IMonitor;
}
export default function RowDashboard({
  id,
  name,
  interval_verif,
  langue,
  mode,
  isActive,
  sendAlert,
  steps,
  deleteOne,
  start,
  stop,
  enableAlert,
  disableAlert,
}: IMonitor) {
  const [DataErrors, setDataErrors] = useState([]);
  const [error, setError] = useState<any>();

  const setErrors = async () => {
    const reponse: any = await MonitorsService.getAllErrors(id);
    if (reponse.status === 200) setDataErrors(reponse.data.data);
    if (reponse.status !== 200 || !reponse.status) setError(reponse.data.error);
  };
  // useEffect(() => {
  //   setErrors();
  //   setInterval(() => {
  //     setErrors();
  //   }, interval_verif * 60000);
  // }, []);
  return (
    <div className="row">
      <div className="cell-name">{name}</div>
      <div className="cell">{langue}</div>
      <div className="cell">
        <Link to={`/moniteur/alertes/${id}`}>
          <span className="material-icons">menu_book</span>
        </Link>
      </div>
      <div className="cell">
        {sendAlert ? (
          <span onClick={disableAlert} className="material-icons font-success">
            toggle_on
          </span>
        ) : (
          <span onClick={enableAlert} className="material-icons font-error">
            toggle_off
          </span>
        )}
      </div>
      <div className="cell">
        <div className="container-actions">
          {isActive ? (
            <span
              onClick={(e) => {
                stop();
                e.preventDefault();
                console.log("yo");
              }}
              className="material-icons"
            >
              stop
            </span>
          ) : (
            <span
              onClick={(e) => {
                start();
                e.preventDefault();
                console.log("yo");
              }}
              className="material-icons"
            >
              rocket_launch
            </span>
          )}
          <a
            href={`${process.env.REACT_APP_BASE_URL}admin/content-manager/collectionType/api::monitor.monitor/${id}`}
            target={"_blank"}
          >
            <span className="material-icons">edit</span>
          </a>
          {/* <span onClick={deleteOne} className="material-icons">delete</span> */}
        </div>
      </div>
    </div>
  );
}
