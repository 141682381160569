import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MonitorsService from "../services/monitors/MonitorsService";

export default function Navbar({ showSidebar, toggleSidebar }: any) {
  const url = useLocation();
  const locationWithoutSlash = (url: string) => {
    return (
      url.replace("/", "").charAt(0).toUpperCase() +
      url.replace("/", "").slice(1)
    );
  };
  const [scrollY, setOffset] = useState(0);

  // RECHERCHE
  const [search, setsearch] = useState("");
  const research = (search: string) => {
    setsearch(search);
    MonitorsService.filterMonitors(search, []);
  };
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <header
      className={scrollY > 99 ? "navbar-desktop bg-light" : "navbar-desktop"}
    >
      <div className="navbar-container">
        <div className="navbar-url-container">
          <div className="navbar-url subtitle">
            <Link to={"/moniteur"}>
              <span className="material-icons">home</span>
            </Link>
            <span className="separator">/</span>
            {locationWithoutSlash(url.pathname)}
          </div>
          <div className="navbar-label title">
            {locationWithoutSlash(url.pathname)}
          </div>
        </div>
        <div className="navbar-tool-container">
          {/* BARRE DE RECHERCHE */}
          {/* <div className="navbar-tool-searchbar">
                        <div className="navbar-tool-searchbar-icon">
                            <span className="material-icons">search</span>
                        </div>
                        <div className="navbar-tool-searchbar-input">
                            <input placeholder="Parcours..." type="text" value={search} onChange={(e) => research(e.target.value)} />
                        </div>
                    </div> 
                    {/* GROUPE ICONES */}
          <div className="navbar-tool-group">
            <button
              className="navbar-tool-group-icon"
              onClick={() => toggleSidebar()}
            >
              <span className="material-icons">
                {showSidebar ? "menu_open" : "menu"}
              </span>
            </button>
            {/* <button className='navbar-tool-group-icon'>
                            <span className="material-icons">notifications</span>
                        </button> */}
            <a
              href={`${process.env.REACT_APP_BASE_URL}admin/content-manager/collectionType/plugin::users-permissions.user/1`}
              target="_blank"
            >
              <button className="navbar-tool-group-icon">
                <span className="material-icons">person</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
