import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMonitor } from "../../services/monitors/IMonitors";
import MonitorsService from "../../services/monitors/MonitorsService";
import RowDashboard from "./RowDashboard";

interface IDashboardMonitor {
  Monitors: any;
  start?: any;
  stop?: any;
  deleteOne?: any;
  enableAlert?: any;
  disableAlert?: any;
  search?: any;
  research?: any;
  IndexPagination?: any;
  Entries?: any;
  editIndexPagination?: any;
}

export default function Dashboard({
  Monitors,
  start,
  stop,
  deleteOne,
  enableAlert,
  disableAlert,
  search,
  research,
  IndexPagination,
  Entries,
  editIndexPagination,
}: IDashboardMonitor) {
  return (
    <>
      <section className="container-main">
        <div className="container-row">
          <div className="container-col-100">
            <div className="dashboard">
              <div className="dashboard-header">
                <div className="presentation">
                  <h3 className="big-title">Journal des Moniteurs</h3>
                  <div className="subtitle">
                    {Monitors && Monitors.data.length} moniteurs trouvées
                  </div>
                </div>
                {/* BARRE DE RECHERCHE */}
                <div className="navbar-tool-searchbar">
                  <div className="navbar-tool-searchbar-icon">
                    <span className="material-icons">search</span>
                  </div>
                  <div className="navbar-tool-searchbar-input">
                    <input
                      placeholder="Parcours..."
                      type="text"
                      value={search}
                      onChange={(e) => research(e.target.value)}
                    />
                  </div>
                </div>
                {/* AJOUT MONITOR */}
                {/* <div className="table-action">
                                    <a href={`${process.env.REACT_APP_BASE_URL}admin/content-manager/collectionType/api::monitor.monitor/create`} target={"_blank"}>
                                        <button className='btn bg-gradient-info'>
                                            <span className="material-icons">smart_toy</span>
                                        </button>
                                    </a>
                                </div> */}
                {/* EN-TÊTES */}
                <div className="row">
                  <div className="cell title">Moniteur</div>
                  <div className="cell title">Langue</div>
                  <div className="cell title">Rapports</div>
                  <div className="cell title">Alerte</div>
                  <div className="cell title">Actions</div>
                </div>
              </div>
              {/* <div className="dashboard-filter">

                            </div> */}
              <div className="dashboard-body">
                {Monitors.data
                  .slice(
                    Entries * (IndexPagination - 1),
                    Entries * IndexPagination
                  )
                  .map((monitor: any, i: number) => {
                    return (
                      <RowDashboard
                        key={monitor?.id}
                        id={monitor?.id}
                        name={monitor?.attributes?.general?.name}
                        interval_verif={
                          monitor?.attributes?.general?.interval_verif
                        }
                        langue={monitor?.attributes?.general?.langue}
                        mode={monitor?.attributes?.status?.mode}
                        isActive={monitor?.attributes?.status?.isActive}
                        sendAlert={monitor?.attributes?.status?.sendAlert}
                        steps={monitor?.steps}
                        deleteOne={() => deleteOne(monitor?.id)}
                        start={() => start(monitor?.id, monitor?.status?.id)}
                        stop={() => stop(monitor?.id, monitor?.status?.id)}
                        enableAlert={() =>
                          enableAlert(monitor?.id, monitor?.status?.id)
                        }
                        disableAlert={() =>
                          disableAlert(monitor?.id, monitor?.status?.id)
                        }
                      />
                    );
                  })}
              </div>

              <div className="dashboard-footer">
                <div className="text-entries">{Entries} Entrées par pages</div>
                {Monitors.data.length > Entries && (
                  <div className="pagination">
                    {IndexPagination !== 1 && (
                      <div
                        onClick={() => editIndexPagination("previous")}
                        className="pagination-prev"
                      >
                        <span className="material-icons">navigate_before</span>
                      </div>
                    )}

                    <div className="pagination-index">
                      <span>
                        {IndexPagination} /{" "}
                        {Math.ceil(Monitors.data.length / Entries)}
                      </span>
                    </div>

                    <div
                      onClick={() => editIndexPagination("next")}
                      className="pagination-next"
                    >
                      {IndexPagination !==
                        Math.ceil(Monitors.data.length / Entries) && (
                        <span className="material-icons">navigate_next</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
