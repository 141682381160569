import http from "../../utils/http";
import moment from "moment";

class RapportMonitorService {
  async getAll() {
    const response: any = await http
      .get(`rapport-monitors?populate=monitor`)
      .then(async (response) => {
        console.log("response :>> ", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getOne(id: number) {
    // return http.get(`monitor-transactions`);
    const response: any = await http
      .get(`rapport-monitors/${id}?populate=*`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getLastWeek() {
    const response: any = await http
      .get(`rapport-monitors?populate=monitor`)
      .then(async (response) => {
        console.log("response :>> ", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getThisWeek() {
    const response: any = await http
      .get(`rapport-monitors?populate=monitor`)
      .then(async (response) => {
        console.log("response :>> ", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getThisMonth() {
    const response: any = await http
      .get(`rapport-monitors?populate=monitor`)
      .then(async (response) => {
        console.log("response :>> ", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  filterWithIdMonitor(idMonitor: number, arrayOfRapports: any) {
    const results = arrayOfRapports?.data?.filter(
      (rapport: any) => rapport?.monitor?.id == idMonitor
    );
    // const results: any = []
    // console.log('results', results)
    // arrayOfRapports.map((rapport:any) =>  console.log('rapport.monitor.id', rapport?.monitor?.id))
    return results;
  }
  GetNameWithIdMonitor(idMonitor: number, arrayOfRapports: any) {
    const results = arrayOfRapports.find(
      (rapport: any) => rapport?.monitor?.id == idMonitor
    );
    return results.monitor?.general?.name;
  }
  // Retourne un tableau avec le nombre d'erreur pour chaque jour
  async filterRapportsWithDay(Rapports: any) {
    const RapportsFilterWithDay: any = {
      lundi: [],
      mardi: [],
      mercredi: [],
      jeudi: [],
      vendredi: [],
      samedi: [],
      dimanche: [],
    };
    Rapports.map((rapport: any) => {
      // if (moment(rapport.createdAt).day() == 1) console.log('lundi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 2)  console.log('mardi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 3)  console.log('mercredi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 4)  console.log('jeudi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 5)  console.log('vendredi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 6)  console.log('samedi', moment(rapport.createdAt).day())
      // if (moment(rapport.createdAt).day() == 0)  console.log('dimanche', moment(rapport.createdAt).day())
      if (moment(rapport.createdAt).day() == 1)
        RapportsFilterWithDay.lundi.push(rapport);
      if (moment(rapport.createdAt).day() == 2)
        RapportsFilterWithDay.mardi.push(rapport);
      if (moment(rapport.createdAt).day() == 3)
        RapportsFilterWithDay.mercredi.push(rapport);
      if (moment(rapport.createdAt).day() == 4)
        RapportsFilterWithDay.jeudi.push(rapport);
      if (moment(rapport.createdAt).day() == 5)
        RapportsFilterWithDay.vendredi.push(rapport);
      if (moment(rapport.createdAt).day() == 6)
        RapportsFilterWithDay.samedi.push(rapport);
      if (moment(rapport.createdAt).day() == 0)
        RapportsFilterWithDay.dimanche.push(rapport);
    });
    return RapportsFilterWithDay;
  }
  // Récupérer le nombre de rapports cette semaine
  // Récupérer le nombre de rapports cette semaine
  async filterRapportsThisWeek(Rapports: any) {
    // La semaine commence lundi donc on récupère toutes les alertes supérieure ou égale à lundi minuit
    const mondayFormat = moment().startOf("week").weekday(1).format();
    const rapportWeek = Rapports.data.filter((rapport: any) => {
      if (moment(rapport.createdAt).format() >= mondayFormat) return rapport;
    });
    return rapportWeek;
  }
  // Récupérer le nombre de rapports ce mois
  async filterRapportsThisMonth(Rapports: any) {
    // La semaine commence lundi donc on récupère toutes les alertes supérieure ou égale à lundi minuit
    const firstDayOfMonth = moment().startOf("month").weekday(1).format();
    console.log("firstDayOfMonth", firstDayOfMonth);
    const rapportMonth = Rapports?.data?.filter((rapport: any) => {
      if (moment(rapport.createdAt).format() >= firstDayOfMonth) return rapport;
    });
    console.log("rapportMonth :>> ", rapportMonth);
    return rapportMonth;
  }
  // Récupérer le nombre de rapports de la semaine dernière
  async filterRapportsLastWeek(Rapports: any) {
    const mondayWeekBefore = moment()
      .subtract(7, "d")
      .startOf("week")
      .day("Monday")
      .format();
    const mondayThisWeek = moment().startOf("week").day("Monday").format();
    const rapportLastWeek = Rapports?.data?.filter((rapport: any) => {
      if (
        mondayWeekBefore <= moment(rapport.createdAt).format() &&
        moment(rapport.createdAt).format() <= mondayThisWeek
      ) {
        return rapport;
      }
    });
    return rapportLastWeek;
  }
  async exportChartRapportsThisWeek() {
    console.log("exportChartRapportsThisWeek");
    //save to png
    const canvasSave: any = document.getElementById("stackD");
    if (canvasSave != null)
      canvasSave.toBlob(function (blob: any) {
        // saveAs(blob, "testing.png")
      });
  }
}
export default new RapportMonitorService();
