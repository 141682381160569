import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthService from '../services/Auth/AuthService';
import CustomLink from './CustomLink'

export default function Sidebar({showSidebar, toggleSidebar} : any) {
    let navigate = useNavigate();
    const disconnect = async () => {
        toggleSidebar();
        AuthService.logout();
        navigate('/connexion');
    }

    return (
        <aside className={showSidebar ? 'sidebar-desktop' : 'sidebar-desktop remove'}>
            <div className="row-logo">
                <div className="sidebar-close-btn-container" onClick={() => toggleSidebar()}>
                    <span className="material-icons">close</span>
                </div>
                <img src="https://voyages.carrefour.fr/carrefour/images/header/logo-carrefour-voyages.svg" alt="Logo Carrefour Voyages" />
                <Link to={'/'} className='title'>
                    Santa Monica
                </Link>
            </div>
            <hr />
            <div className='links-container'>
                
                <CustomLink onClick={() => toggleSidebar()} to="/moniteur">
                    <div className="row-link-container">
                        <div className="row-link">
                            <div className="link-icon-container">
                                <span className="material-icons">smart_toy</span>
                            </div>
                            <div className="link-text-container">
                                Moniteurs
                            </div>
                        </div>
                    </div>
                </CustomLink>
                <CustomLink onClick={() => toggleSidebar()} to="/alerte">
                    <div className="row-link-container">
                        <div className="row-link">
                            <div className="link-icon-container">
                                <span className="material-icons">report</span>
                            </div>
                            <div className="link-text-container">
                                Alertes
                            </div>
                        </div>
                    </div>
                </CustomLink>
                <a onClick={() => toggleSidebar()} href={`${process.env.REACT_APP_BASE_URL}admin`} target={'blank'}>
                    <div className="row-link-container">
                        <div className="row-link">
                            <div className="link-icon-container">
                                <span className="material-icons">settings</span>
                            </div>
                            <div className="link-text-container">
                                Back Office
                            </div>
                        </div>
                    </div>
                </a>
                {/* <CustomLink onClick={() => toggleSidebar()} to="/dashboard">
                    <div className="row-link-container">
                        <div className="row-link">
                            <div className="link-icon-container">
                                <span className="material-icons">grid_view</span>
                            </div>
                            <div className="link-text-container">
                                Dashboard
                            </div>
                        </div>
                    </div>
                </CustomLink> */}
                <span className='sidebar-subtitle'>ACCOUNT PAGES</span>
                <div style={{cursor: 'pointer'}} onClick={() => disconnect()} >
                    <div className="row-link-container">
                        <div className="row-link">
                            <div className="link-icon-container">
                            <span className="material-icons">power_settings_new</span>
                            </div>
                            <div className="link-text-container">
                                Déconnexion
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}
