import axios from "axios";
import http from "../../utils/http";

class AuthService {
    
    checkConnected() {
        const userStorage : any = localStorage.getItem('user');
        const user = JSON.parse(userStorage);
        if (!user) return false;
        if (user) return true;
    }
    async login(identifier : string, password : string) {
        const response = await http
            .post('auth/local', {identifier: identifier, password: password})
            .then(async (response) => {
                console.log('response :>> ', response);
                const user = {
                    accessToken: response.data.jwt,
                    data: response.data.user 
                }
                localStorage.setItem('user', JSON.stringify(user));
                return response;
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error.response);
                return error.response;
            });
        return response
    }
    async logout() {
        localStorage.removeItem('user');
    }
}
export default new AuthService();