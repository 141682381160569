import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.scss';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import AuthService from './services/Auth/AuthService';

function App() {
  const [showSidebar, setshowSidebar] = useState(true);
  const toggleSidebar = () => setshowSidebar(!showSidebar);
  let navigate = useNavigate();

  useEffect(() => {
    if(!AuthService.checkConnected()) navigate('/connexion');
  })

  return (
    <div className='container'>
      <Sidebar
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
      />
      <main>
        <Navbar 
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
        />
        <Outlet />
      </main>
    </div>
  );
}

export default App;

