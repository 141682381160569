import http from "../../utils/http";
import { IMonitor } from "./IMonitors";

class MonitorsService {
  async getAll() {
    // return http.get(`monitors`);
    const response: any = await http
      .get(`monitors?populate=*`)
      .then(async (response) => {
        console.log("response", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getOne(id: number) {
    // return http.get(`monitors`);
    const response: any = await http
      .get(`monitors/${id}?populate=*`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getNameWithId(id: number) {
    const response: any = await http
      .get(`/monitors/${id}?populate=*`)
      .then(async (response) => {
        return response.data.data.attributes.general.name;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async getAllErrors(id: any) {
    console.log("id", id);
    const response: any = await http
      .get(`/rapport-monitors/monitor/${id}`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async delete(id: number) {
    const response: any = await http
      .delete(`monitors/${id}`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async start(id: number, idComponentStatus: number) {
    const response: any = await http
      .put(`monitors/${id}`, {
        data: { idComponentStatus: idComponentStatus, isActive: true },
      })
      .then(async (response) => {
        console.log("response", response);
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async stop(id: number, idComponentStatus: number) {
    const response: any = await http
      .put(`monitors/${id}`, {
        data: { idComponentStatus: idComponentStatus, isActive: false },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async enableAlert(id: number, idComponentStatus: number) {
    const response: any = await http
      .put(`monitors/${id}`, {
        data: { idComponentStatus: idComponentStatus, sendAlert: true },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  async disableAlert(id: number, idComponentStatus: number) {
    const response: any = await http
      .put(`monitors/${id}`, {
        data: { idComponentStatus: idComponentStatus, sendAlert: false },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        return error.response;
      });
    return response;
  }
  filterMonitors(filterWord: string, arrayOfMonitors: IMonitor[]) {
    // Recherche dans les propriétés general et status s'il y'a une correspondance avec la recherche
    const results = arrayOfMonitors.filter((monitor) => {
      if (
        Object.values(monitor.general).includes(filterWord) ||
        Object.values(monitor.status).includes(filterWord)
      )
        return true;
    });
    return results;
  }
}
export default new MonitorsService();
