import React, { useEffect, useState } from "react";
import Dashboard from "../components/Monitors/Dashboard";
import { IMonitor } from "../services/monitors/IMonitors";
import MonitorsService from "../services/monitors/MonitorsService";

export default function Monitors() {
  const [Monitors, setMonitors] = useState<IMonitor[]>([]);
  const [CopyMonitors, setCopyMonitors] = useState<IMonitor[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  // Recherche
  const [search, setsearch] = useState("");
  const research = (search: string) => {
    setIndexPagination(1);
    setsearch(search);
    setMonitors(MonitorsService.filterMonitors(search, CopyMonitors));
    if (search === "") setMonitors(CopyMonitors);
  };

  // Pagination
  const [IndexPagination, setIndexPagination] = useState<number>(1);
  const [Entries, setEntries] = useState(10);
  const editIndexPagination = (action: string) => {
    if (action === "next") {
      console.log("next");
      setIndexPagination(IndexPagination + 1);
    }

    if (action === "previous") {
      console.log("previous");
      setIndexPagination(IndexPagination - 1);
    }
  };

  const fetchData = async () => {
    const reponse = await MonitorsService.getAll();
    if (reponse.status === 200) {
      setMonitors(reponse.data);
      setCopyMonitors(reponse.data);
      setLoading(true);
    }
    if (reponse.status !== 200 || !reponse.status) setError(reponse.data.error);
  };
  const deleteOne = async (id: number) => {
    setLoading(true);
    await MonitorsService.delete(id);
    setLoading(false);
  };
  const start = async (id: number, idComponentStatus: number) => {
    setLoading(true);
    await MonitorsService.start(id, idComponentStatus);
    setLoading(false);
  };
  const stop = async (id: number, idComponentStatus: number) => {
    setLoading(true);
    await MonitorsService.stop(id, idComponentStatus);
    setLoading(false);
  };
  const enableAlert = async (id: number, idComponentStatus: number) => {
    setLoading(true);
    await MonitorsService.enableAlert(id, idComponentStatus);
    setLoading(false);
  };
  const disableAlert = async (id: number, idComponentStatus: number) => {
    setLoading(true);
    await MonitorsService.disableAlert(id, idComponentStatus);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [isLoading]);

  return (
    <>
      {!isLoading && <h1>En cours de chargement...</h1>}
      {isLoading && (
        <Dashboard
          Monitors={Monitors}
          search={search}
          research={research}
          IndexPagination={IndexPagination}
          Entries={Entries}
          editIndexPagination={editIndexPagination}
          start={start}
          stop={stop}
          deleteOne={deleteOne}
          enableAlert={enableAlert}
          disableAlert={disableAlert}
        />
      )}
    </>
  );
}
