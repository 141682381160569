import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import Dashboard from "../components/Alertes/Dashboard";
import Statistiques from "../components/Alertes/Statistiques";
import RapportMonitorService from "../services/rapport-monitor/RapportMonitorService";

// const StatistiquesComponent = lazy(() => import('../components/Alertes/Statistiques'));
// const DashboardComponent = lazy(() => import('../components/Alertes/Dashboard'));
export default function Alertes() {
  const [Rapports, setRapports] = useState<any>([]);
  const [CopyRapports, setCopyRapports] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [ReportsFilter, setReportsFilter] = useState();
  const [DateRapport, setDateRapport] = useState("ThisWeek");
  const [ModeRapport, setModeRapport] = useState("production");
  const params: any = useParams();
  // Pagination
  const [IndexPagination, setIndexPagination] = useState<number>(1);
  const [Entries, setEntries] = useState(10);
  const editIndexPagination = (action: string) => {
    if (
      IndexPagination >= 1 &&
      IndexPagination < Math.ceil(Rapports.length / Entries)
    ) {
      if (action == "next") setIndexPagination(IndexPagination + 1);
    }
    if (
      IndexPagination > 1 &&
      IndexPagination <= Math.ceil(Rapports.length / Entries)
    ) {
      if (action == "previous") setIndexPagination(IndexPagination - 1);
    }
  };
  const filterRaports = async () => {
    if (DateRapport == "ThisWeek")
      setReportsFilter(
        await RapportMonitorService.filterRapportsThisWeek(Rapports)
      );
    if (DateRapport == "LastWeek")
      setReportsFilter(
        await RapportMonitorService.filterRapportsLastWeek(Rapports)
      );
    if (DateRapport == "ThisMonth")
      setReportsFilter(
        await RapportMonitorService.filterRapportsThisMonth(Rapports)
      );
    // if (ModeRapport == 'production') setReportsFilter(await ReportsFilter.filter((rapport: any) => rapport?.mode == "production" ));
    // if (ModeRapport != 'production') setReportsFilter(await ReportsFilter.filter((rapport: any) => rapport?.mode != "production" ));
  };
  // Recherche
  const [search, setsearch] = useState("");
  const research = (search: string) => {
    setsearch(search);
    // setRapports(MonitorsService.filterMonitors(search, CopyRapports));
  };
  const fetchData = async () => {
    let reponse;
    reponse = await RapportMonitorService.getAll();
    if (reponse.status !== 200 || !reponse.status)
      return setError(reponse.data.error);
    if (reponse.status === 200) {
      console.log("yo");
      setRapports(reponse.data);
      setCopyRapports(reponse.data);
      setLoading(true);
      setReportsFilter(
        await RapportMonitorService.filterRapportsThisWeek(reponse.data)
      );
      // console.log('ReportsFilter', await RapportMonitorService.filterRapportsThisWeek(reponse.data))
      if (params.monitorId) {
        setRapports(
          RapportMonitorService.filterWithIdMonitor(
            params.monitorId,
            reponse.data
          )
        );
      }
    }
  };
  useEffect(() => {
    filterRaports();
  }, [DateRapport]);

  useEffect(() => {
    fetchData();
  }, [isLoading]);
  return (
    <section className="container-main">
      {!isLoading && <h1>En cours de chargement...</h1>}
      {isLoading && (
        <>
          <Statistiques
            ReportsFilter={ReportsFilter}
            DateRapport={DateRapport}
            setDateRapport={setDateRapport}
            ModeRapport={ModeRapport}
            setModeRapport={setModeRapport}
          />
          <Dashboard
            Rapports={Rapports}
            editIndexPagination={editIndexPagination}
            IndexPagination={IndexPagination}
            Entries={Entries}
            search={search}
            research={research}
          />
        </>
      )}
    </section>
  );
}
