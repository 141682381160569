import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/Auth/AuthService';

export default function Signin() {
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');

    
    const sign = async () => {
        setLoading(true);
        const reponse = await AuthService.login(email, password);
        setLoading(false);
        if (reponse.status == 200) navigate('/moniteur', {replace: true});
        if (reponse.status !== 200 || !reponse.status) setError(reponse.data.error);
    }

    useEffect(() => {
        if(AuthService.checkConnected()) navigate('/moniteur', {replace: true})
    },[])
    return (
        <>
            <div className="layout-connexion">
                {isLoading && <div className="container-loader"><div className='loader'></div></div>}
                <div className="container-form">
                    <div className="header-form">
                        <h2>Santa Monica</h2>
                        {/* {error && <p>{error.message}</p>} */}
                        {/* <p>Entrez votre email et mot de passe pour vous connecter</p> */}
                    </div>
                    <div className="container-form-connexion">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="container-row-form">
                                <div className="container-label-form">
                                    <label> 
                                        Email
                                    </label>
                                </div>
                                <div className="container-input-form">
                                    <input onChange={(e) => setemail(e.target.value)} value={email} placeholder='johndoe@mail.com' />
                                </div>
                            </div>
                            <div className="container-row-form">
                                <div className="container-label-form">
                                    <label> 
                                        Password
                                    </label>
                                </div>
                                <div className="container-input-form">
                                    <input type={'password'} onChange={(e) => setpassword(e.target.value)} value={password} placeholder='Mot de passe' />
                                </div>
                            </div>
                            <div className="container-btn-form" onClick={() => sign()}>
                                <button>
                                    Connexion
                                </button>
                            </div>
                            <div className="container-img">
                                <img src="https://voyages.carrefour.fr/carrefour/images/header/logo-carrefour-voyages.svg" alt="Logo Carrefour Voyages" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

function ErrorPopinSign( {error} : any) {
    return (
        <>
            <div className="error">
                <h1>Status : {error.status}</h1>
                <h1>Name : {error.name}</h1>
                <h1>Message : {error.message} </h1>
            </div>
        </>
    )
}
